<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('Form.Section.SubmitOfferNoOfferResponse')"
        :cards="items"
        :statuses="statuses"
        v-slot="{ card, status }"
      >
        <pro-deck-card
          v-bind="card"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        >
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  data() {
    return {
      items: [],

      statuses: [
        {
          key: "NoOffer",
          label: this.getRes("CustMenu.Com.Action.SubmitNoOffer"),
          color: "orange",
        },
      ],
    };
  },

  methods: {
    async getTenderers() {
      return (
        await this.$proSmart.tenderView.getNoOfferResponses(
          this,
          this.$route.params.id
        )
      ).map(({ name, responseType, processId, lastSubmissionDate }) => {
        let actions;
        if (processId) {
          actions = [{ processId: processId, viewForm: true }];
        }

        let date = null;
        if (lastSubmissionDate) date = new Date(lastSubmissionDate);

        return {
          title: name,
          status: responseType,
          actions,
          date,
        };
      });
    },
  },

  async created() {
    this.items = await this.getTenderers();
  },
};
</script>
